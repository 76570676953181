import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df4a16b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bc-step-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepHeader = _resolveComponent("StepHeader")!
  const _component_ld_loader = _resolveComponent("ld-loader")!
  const _component_StepContainer = _resolveComponent("StepContainer")!

  return (_openBlock(), _createBlock(_component_StepContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_StepHeader, {
        title: _ctx.$t('connection.steps.loading.title')
      }, null, 8, ["title"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ld_loader, { type: "hourglass" })
      ])
    ]),
    _: 1
  }))
}