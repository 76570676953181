
import { defineComponent, computed, watch } from 'vue'

import StepContainer from '@/components/LdStepper/StepContainer/StepContainer.vue'
import StepHeader from '@/components/LdStepper/StepHeader/StepHeader.vue'
import { useStore } from 'vuex'
import { Steps } from '@/views/connection/Connection.vue'
import { sendPageViewEvent, AppVirtualUrls } from '@/services/segmentTracking'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	components: {
		StepContainer,
		StepHeader,
	},
	emits: ['nextPage'],
	setup(props, { emit }) {
		const store = useStore()
		const max = 4
		const current = 1
		const loaded = computed(() => store.getters['connection/properties/isLoaded'])

		watch(loaded, () => {
			if (loaded.value) {
				emit('nextPage', Steps.SUCCESS)
			}
		})

		return {
			current,
			max,
		}
	},
	mounted() {
		const { t } = useI18n()

		sendPageViewEvent({
			locationTitle: t('connection.steps.loading.title'),
			virtualUrl: AppVirtualUrls.applyScript,
		})
	},
})
